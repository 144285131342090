import React, { Component, Fragment } from "react";
import Slick from "react-slick";
import styles from "./cyclingBanner.scss";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import ErrorBoundary from "../../Utilities/ErrorBoundary";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { Button, Primary, Secondary } from "../Buttons/Buttons";
import { RotatingPlane } from "../Spinners/Spinners";
export class Banner extends Component {
  state = {
    slider: null,
    slideIndex: 0
  };
  static propTypes = {
    settings: PropTypes.object.isRequired,
    banners: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
  };
  goToSlide = index => {
    this.slider.slickPause();
    this.slider.slickGoTo(index);
  };
  slideBackward = () => {
    this.slider.slickPause();
    this.slider.slickPrev();
  };
  slideForward = () => {
    this.slider.slickPause();
    this.slider.slickNext();
  };
  render() {
    const { settings, banners, title: heading } = this.props;
    const { slideIndex } = this.state;
    const showArrows = !!settings["Banner Arrows"];
    const showDots = !!settings["Banner Dots"];

    const parsedSettings = {
      speed:
        settings && settings["Slideshow Speed"]
          ? +settings["Slideshow Speed"]
          : 1000,
      autoplaySpeed:
        settings && settings["Slideshow Delay"]
          ? +settings["Slideshow Delay"]
          : 2000
    };

    const slickSettings = {
      fade: true,
      autoplay: true,
      arrows: false,
      infinite: true,
      beforeChange: (current, next) => {
        this.setState({ slideIndex: next });
      },
      ...parsedSettings
    };
    return (
      <Fragment>
        <h2 className={`${styles.heading}`}>{heading}</h2>
        <Slick
          ref={slider => (this.slider = slider)}
          {...slickSettings}
          className={`${styles.slider} ${styles.loaded}`}
          id="cyclingBannerParallax"
        >
          {banners.map((banner, index) => {
            const { button, focused_img, title, caption } = banner;
            const parsedimg = JSON.parse(focused_img);
            const { config, id } = parsedimg;
            const backgroundPosition = config.backgroundPosition || "center";
            const btn = button && JSON.parse(button);
            const imageString = `/image/${id}`;

            return (
              <figure key={banner.id} className={styles.slide}>
                <img
                  className={styles.image}
                  id={`banner-${index}`}
                  src={`${imageString}/1800`}
                  srcSet={`${imageString}/500 500w,
                    ${imageString}/1000 1000w,
                    ${imageString}/1500 1500w,
                    ${imageString}/2000 2000w,
                    `}
                  alt=""
                  style={{ objectPosition: backgroundPosition }}
                />
                <figcaption className={styles.figcaption}>
                  <div className={styles.current}>
                    <h3 className={styles.title}>{title}</h3>
                    <p className={styles.text}>{caption}</p>

                    {button && btn.url && (
                      <Button color="primary" outline={false} href={btn.url}>
                        {btn.text}
                      </Button>
                    )}
                  </div>
                </figcaption>
              </figure>
            );
          })}
        </Slick>
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Slider Controls</legend>
          <div className={styles.controls}>
            {showArrows && (
              <button
                className={styles.prevButton}
                onClick={this.slideBackward}
              >
                <i className="fas fa-arrow-circle-left" />
                <span>Previous Slide</span>
              </button>
            )}
            {showDots && (
              <nav className={styles.indicators}>
                {banners.map((banner, index) => {
                  return (
                    <label
                      key={banner.id}
                      onClick={() => this.goToSlide(index)}
                      className={`${styles.indicatorLabel} ${
                        index === slideIndex ? styles.active : ""
                      }`}
                      htmlFor={`indexIndicator${index}`}
                    >
                      <input
                        type="radio"
                        id={`indexIndicator${index}`}
                        name="indexIndicator"
                        className={`${styles.indicator} `}
                      />
                      <span> Slide {index} button</span>
                    </label>
                  );
                })}
              </nav>
            )}
            {showArrows && (
              <button className={styles.nextButton} onClick={this.slideForward}>
                <i className="fas fa-arrow-circle-right" />
                <span>Next Slide</span>
              </button>
            )}
          </div>
        </fieldset>
      </Fragment>
    );
  }
}
const BANNER_QUERY = gql`
  query BANNER_QUERY($id: Int!) {
    amsd_cycling_banner(block: $id) {
      id
      button
      focused_img
      title
      caption
    }
    core_blocks(id: $id) {
      settings {
        setting
        value
      }
    }
  }
`;
class CyclingBanner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, title } = this.props;

    return (
      <ErrorBoundary>
        <Query query={BANNER_QUERY} variables={{ id: id }}>
          {({ loading, error, data }) => {
            if (loading) {
              return <RotatingPlane />;
            } else if (error) {
              throw error;
            } else {
              const { core_blocks, amsd_cycling_banner } = data;
              const { settings: settingsArray } = core_blocks[0];

              const settings = {};
              settingsArray.map(pair => (settings[pair.setting] = pair.value));

              return (
                <div className={styles.wrapper}>
                  <Banner
                    settings={settings}
                    title={title}
                    banners={amsd_cycling_banner}
                  />
                </div>
              );
            }
          }}
        </Query>
      </ErrorBoundary>
    );
  }
}

CyclingBanner.propTypes = {
  id: PropTypes.number.isRequired
};

export default CyclingBanner;
