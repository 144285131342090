import React from "react";
import styles from "./spinners.scss";

export const DoubleBounce = props => {
  return (
    <div className={styles.spinner}>
      <div className={styles.doubleBounce1} />
      <div className={styles.doubleBounce2} />
    </div>
  );
};
export const RotatingPlane = props => {
  return <div className={styles.rotatingPlane} {...props.style} />;
};
export const CubeGrid = props => {
  return (
    <div className={`${styles.cubeGrid}`} {...props.style}>
      <div className={`${styles.cube} ${styles.cube1}`} />
      <div className={`${styles.cube} ${styles.cube2}`} />
      <div className={`${styles.cube} ${styles.cube3}`} />
      <div className={`${styles.cube} ${styles.cube4}`} />
      <div className={`${styles.cube} ${styles.cube5}`} />
      <div className={`${styles.cube} ${styles.cube6}`} />
      <div className={`${styles.cube} ${styles.cube7}`} />
      <div className={`${styles.cube} ${styles.cube8}`} />
      <div className={`${styles.cube} ${styles.cube9}`} />
    </div>
  );
};
export const BallLine = props => {
  return (
    <div className={styles.ballLine} {...props.style} id="loadPercent">
      <p className={styles.text}>Loading</p>
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
    </div>
  );
};
