import ReactDOM from "react-dom";
import ErrorBoundary from "../../Utilities/ErrorBoundary";
import React from "react";
import { Banner } from "./CyclingBanner";

if (PRODUCTION && document) {
  const bannerJSON = JSON.parse(document.getElementById("bannerData").innerHTML);
  const config = {
    ...bannerJSON
  };
  ReactDOM.hydrate(
    <ErrorBoundary>
      <Banner {...config} />
    </ErrorBoundary>,
    document.getElementById("cyclingBanner")
  );
}
