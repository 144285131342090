import React, { Fragment } from "react";
import styles from "./buttons.scss";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

const props = {
  link: PropTypes.string,
  text: PropTypes.string,
  styl: PropTypes.object,
  type: PropTypes.string,
  btn: PropTypes.bool
};

export const Button = props => {
  const { btn, className, color, type, href, outline, children } = props;
  const opts = {};
  const text = props.children ? props.children : props.text;
  let cls = `${className} ` || ``;
  switch (color) {
    case "primary":
      cls += styles.primary;
      break;
    case "secondary":
      cls += styles.secondary;
      break;
    case "white":
      cls += styles.white;
      break;
    default:
      cls += styles.primary;
  }

  if (outline) {
    cls += ` ${styles.outline}`;
  }

  if (props.download) {
    opts["download"] = true;
  }
  if (props.disabled) {
    opts["disabled"] = true;
  }
  if (props.external) {
    opts["target"] = "_blank";
  }
  const textArray = text && text.split("|");

  const content = text ? (
    <Fragment>
      {textArray[0]}
      {textArray[1] ? <span>{textArray[1]}</span> : ""}
    </Fragment>
  ) : (
    children
  );
  return props.btn ? (
    <button
      {...opts}
      type={props.type || "submit"}
      className={cls}
      style={props.style}
      onClick={props.onClick}
    >
      {content}
    </button>
  ) : props.external || props.download || PRODUCTION ? (
    <a className={cls} href={href} {...opts} style={props.style}>
      {content}
    </a>
  ) : (
    <Link to={href} className={cls} style={props.style}>
      {content}
    </Link>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "white"]).isRequired,
  outline: PropTypes.bool.isRequired,
  btn: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.any
};

export { styles as buttons };
