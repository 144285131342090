import React from "react";
import styles from "Styles/main.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
    // You can also log the error to an error reporting service
    console.error(error);
    console.info(info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.error}>
          <div className={styles.container}>
            <h2>Oops, something went wrong :(</h2>
            <p>The error: {this.state.error.toString()}</p>
            <p>Where it occurred: {this.state.info.componentStack}</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  //prop: PropTypes.string.isRequired
};
export default ErrorBoundary;
